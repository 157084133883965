/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { Link } from "gatsby"

import { MdChevronRight as ChevronRight } from "react-icons/md"
import { getItemList } from "../../utils/gatsby-sidebar/item-list"
import getActiveItem from "../../utils/gatsby-sidebar/get-active-item"
import getActiveItemParents from "../../utils/gatsby-sidebar/get-active-item-parent"

export const Separator = ({ character = <ChevronRight /> }) =>(
  <span
    role="presentation"
    sx={{ m: `0 0.25rem` }}
  >
    {character}
  </span>  
)

export const BreadcrumbNav = ({ children }) => (
  <nav
    aria-label="breadcrumb"
    sx={{
      color: `textMuted`,
      display: `inherit`,
      fontSize: `0.875rem`,
      mb: `2.5rem`,
    }}
  >
   {children}
  </nav>
)

const Breadcrumb = ({ location }) => {
  const itemList = getItemList(location.pathname)
  if (itemList === undefined) return null

  const activeItem = getActiveItem(itemList.items, location)
  const activeItemParents = getActiveItemParents(itemList.items, activeItem)
  const topLevel = itemList.key
  const topLevelTitle = itemList.breadcrumbTitle || itemList.title
  
  // The Introduction page is an exception from the norm for producing the breadcrumb.
  // It needs to be handle separately.

  if (activeItem.title === `Introduction`) {
    return (
        <BreadcrumbNav>
          <Link to="/">Home</Link>
          <Separator />
          {topLevelTitle}
        </BreadcrumbNav>
    )
  }

  return (
    <Fragment>
      <BreadcrumbNav>
        <Link to="/">Home</Link>
        <Separator />
        <Link to={`/${topLevel}/`}>{topLevelTitle}</Link>
        <Separator />
        {activeItemParents.reverse().map(item => {
          const itemTitle = item.breadcrumbTitle || item.title
          return (
            <Fragment key={itemTitle}>
              <span>
                <Link to={item.link}>{itemTitle}</Link>
              </span>
              <Separator />
            </Fragment>
          )
        })}
        <span aria-current="page">
          {activeItem.breadcrumbTitle || activeItem.title}
        </span>
      </BreadcrumbNav>
    </Fragment>
  )
}

export default Breadcrumb