import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import NavLink from "./nav-link"


const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `black`,
      height: `6rem`,
      marginBottom: `1.45rem`,
      position: `fixed`,
      width: `100%`,
      zIndex: 5,
    }}
  >
    <nav
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Link
        to="/"
        style={{
          color: `white`,
          fontSize: `2rem`,
          textDecoration: `none`,
        }}
      >
        {siteTitle}
      </Link>

      <NavLink to="/index-project/">Projects</NavLink>
      <NavLink to="/index-blog/">Blog</NavLink>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
