const isParentActive = (sections, parentTitle) => {
  for (let section of sections) {
    if (section.title === parentTitle) return section

    if (section.items) {
      for (let item of section.items) {
        const activeSubItem = isParentActive([item], parentTitle)
        if (activeSubItem) return activeSubItem
      }
    }
  }
}

const getActiveItemParents = (itemList, activeItemLink, activeItemParents = []) => {
  if (activeItemLink.parentTitle) {
    const parentActive = isParentActive(itemList, activeItemLink.parentTitle)
    activeItemParents.push(parentActive)
    return getActiveItemParents(itemList, parentActive, activeItemParents)
  } else {
    return activeItemParents
  }
}

export default getActiveItemParents