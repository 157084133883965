/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"

import Header from "./header"
//import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        className="main-body docSearch-content"
        css={css`
            padding-left: env(safe-area-inset-left);
            padding-right: env(safe-area-inset-left);
            padding-top: 6rem;
            padding-bottom: 0rem;
            margin: 0 auto;
            @media (min-width: 42rem) {
                padding-top: calc(6rem);
                padding-bottom: 0rem;
            }
        `}
      >
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
